import Vue from "vue";
import Router from "vue-router";
import { api } from "@/state/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: "",
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      component: () => import("./views/home"),
      meta: { authRequired: true },
    },
    {
      path: "/login",
      component: () => import("./views/login"),
      meta: { authRequired: false },
    },
    {
      path: "/login/token/:token",
      component: () => import("./views/login-in"),
      meta: { authRequired: false },
    },
    {
      path: "/logout",
      redirect: "/login",
    },
    {
      path: "/register",
      component: () => import("./views/register"),
      meta: { authRequired: false },
    },
    {
      path: "/register/:sponsor",
      component: () => import("./views/register"),
      meta: { authRequired: false },
    },
    {
      path: "/password-forgot",
      component: () => import("./views/password-forgot"),
      meta: { authRequired: false },
    },
    {
      path: "/password-change",
      component: () => import("./views/password-change"),
      meta: { authRequired: false },
    },
    {
      path: "/upgrade",
      component: () => import("./views/upgrade"),
      meta: { authRequired: true },
    },
    {
      path: "/account",
      component: () => import("./views/account/account"),
      meta: { authRequired: true },
    },
    {
      path: "/account/documents",
      component: () => import("./views/account/documents"),
      meta: { authRequired: true },
    },
    {
      path: "/account/address",
      component: () => import("./views/account/address"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password",
      component: () => import("./views/account/password"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password/security",
      component: () => import("./views/account/password-security"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password/security/:token",
      component: () => import("./views/account/password-security"),
      meta: { authRequired: true },
    },
    {
      path: "/account/pix",
      component: () => import("./views/account/pix"),
      meta: { authRequired: true },
    },
    {
      path: "/contracts",
      component: () => import("./views/contracts/list"),
      meta: { authRequired: true },
    },
    {
      path: "/contracts/:id",
      component: () => import("./views/contracts/view"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/extract",
      component: () => import("./views/wallet/extract"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/transfer",
      component: () => import("./views/wallet/transfer"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/pay",
      component: () => import("./views/wallet/pay"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/pay/:id",
      component: () => import("./views/wallet/pay"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/withdrawal",
      component: () => import("./views/wallet/withdrawal"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/withdrawal/history",
      component: () => import("./views/wallet/withdrawal-history"),
      meta: { authRequired: true },
    },
    {
      path: "/network/clients",
      component: () => import("./views/network/clients"),
      meta: { authRequired: true },
    },
    {
      path: "/network/sponsored",
      component: () => import("./views/network/sponsored"),
      meta: { authRequired: true },
    },
    {
      path: "/network/team",
      component: () => import("./views/network/team"),
      meta: { authRequired: true },
    },
    {
      path: "/network/matriz",
      component: () => import("./views/network/matriz"),
      meta: { authRequired: true },
    },
    {
      path: "/network/unilevel",
      component: () => import("./views/network/unilevel"),
      meta: { authRequired: true },
    },
    {
      path: "/network/unilevel/:id",
      component: () => import("./views/network/unilevel"),
      meta: { authRequired: true },
    },
    {
      path: "/reports/bonus",
      component: () => import("./views/reports/bonus"),
      meta: { authRequired: true },
    },
    {
      path: "/reports/ecommerce",
      component: () => import("./views/reports/ecommerce"),
      meta: { authRequired: true },
    },
    {
      path: "/reports/indicacao",
      component: () => import("./views/reports/indicacao"),
      meta: { authRequired: true },
    },
    {
      path: "/reports/unilevel",
      component: () => import("./views/reports/unilevel"),
      meta: { authRequired: true },
    },
    {
      path: "/reports/graduation",
      component: () => import("./views/reports/graduation"),
      meta: { authRequired: true },
    },
    {
      path: "/reports/premiations",
      component: () => import("./views/reports/premiations"),
      meta: { authRequired: true },
    },
    {
      path: "/store",
      redirect: "/store/featured",
    },
    {
      path: "/store/featured",
      component: () => import("./views/store/featured"),
      meta: { authRequired: true },
    },
    {
      path: "/store/category/:id",
      component: () => import("./views/store/category"),
      meta: { authRequired: true },
    },
    {
      path: "/store/search",
      component: () => import("./views/store/search"),
      meta: { authRequired: true },
    },
    {
      path: "/store/cart",
      component: () => import("./views/store/cart"),
      meta: { authRequired: true },
    },
    {
      path: "/store/orders",
      component: () => import("./views/store/orders"),
      meta: { authRequired: true },
    },
    {
      path: "/store/order/:id",
      component: () => import("./views/store/order"),
      meta: { authRequired: true },
    },
    {
      path: "/store/franchises",
      component: () => import("./views/store/franchises"),
      meta: { authRequired: true },
    },
    {
      path: "/downloads",
      component: () => import("./views/downloads"),
      meta: { authRequired: true },
    },
    {
      path: "/faq",
      component: () => import("./views/faq"),
      meta: { authRequired: true },
    },
    {
      path: "/tickets",
      component: () => import("./views/tickets"),
      meta: { authRequired: true },
    },
    {
      path: "/tickets/:id",
      component: () => import("./views/tickets-view"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise",
      component: () => import("./views/franchise/home"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/register",
      component: () => import("./views/franchise/register"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/account",
      component: () => import("./views/franchise/account"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/reports",
      component: () => import("./views/franchise/reports"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/orders",
      component: () => import("./views/franchise/orders"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/orders/:id",
      component: () => import("./views/franchise/order"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/store/cart",
      component: () => import("./views/franchise/store-cart"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/points/extract",
      component: () => import("./views/franchise/points-extract"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/points/orders",
      component: () => import("./views/franchise/points-orders"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/points/orders/:id",
      component: () => import("./views/franchise/points-order"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/points/cart",
      component: () => import("./views/franchise/points-cart"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/inventory",
      component: () => import("./views/franchise/inventory"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/inventory/restock",
      component: () => import("./views/franchise/inventory-restock"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/inventory/orders",
      component: () => import("./views/franchise/inventory-orders"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/inventory/orders/:id",
      component: () => import("./views/franchise/inventory-order"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/wallet/extract",
      component: () => import("./views/franchise/wallet-extract"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/wallet/transfer",
      component: () => import("./views/franchise/wallet-transfer"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/wallet/withdrawal",
      component: () => import("./views/franchise/wallet-withdrawal"),
      meta: { authRequired: true },
    },
    {
      path: "/franchise/wallet/withdrawal/history",
      component: () => import("./views/franchise/wallet-withdrawal-history"),
      meta: { authRequired: true },
    },
    {
      path: "/courses",
      component: () => import("./views/courses/list"),
      meta: { authRequired: true },
    },
    {
      path: "/courses/:course",
      component: () => import("./views/courses/view"),
      meta: { authRequired: true },
    },
    {
      path: "/courses/:course/:module/:lesson",
      component: () => import("./views/courses/view"),
      meta: { authRequired: true },
    },
    {
      path: "/informatives",
      component: () => import("./views/informatives/index"),
      meta: { authRequired: true },
    },
    {
      path: "/informatives/:id",
      component: () => import("./views/informatives/view"),
      meta: { authRequired: true },
    },
    {
      path: "/events",
      component: () => import("./views/events/index"),
      meta: { authRequired: true },
    },
    {
      path: "/events/:id",
      component: () => import("./views/events/view"),
      meta: { authRequired: true },
    },
    {
      path: "/404",
      component: () => import("./views/404"),
      meta: { authRequired: false },
    },
    {
      path: "/:sponsor",
      redirect: "/register/:sponsor",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");

  document.title = process.env.VUE_APP_TITLE;
  next();

  if (to.meta.authRequired && !token) {
    return next("/login");
  }

  if (to.meta.authRequired) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == "success") {
          router.app.$store.state.account.user = response.data.user;

          if (
            response.data.user.contracts &&
            response.data.user.contracts.length > 0 &&
            to.path != "/contracts/:id"
          ) {
            response.data.user.contracts.forEach(con => {
              return next("/contracts/" + con.id);
            })
          } else {
            next();
          }
        }
      })
  }

  next();
});

export default router;
